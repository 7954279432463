<script setup lang="ts">
    import Chip from 'primevue/chip';
    import { useI18n } from 'vue-i18n';

    defineProps<{
        isUsed: boolean;
        showChip: boolean;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="chippedContent">
        <slot name="content"></slot>
        <template v-if="showChip">
            <Chip v-if="isUsed" :label="t('PRODUCT.STATE.USED')" class="usedChip"></Chip>
            <Chip v-else :label="t('PRODUCT.STATE.NEW')" class="newChip"></Chip>
        </template>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as main;

    .chippedContent {
        position: relative;
        display: inline-block;

        .p-chip {
            position: absolute;
            top: main.$spacing-4;
            left: main.$spacing-4;
            border-radius: main.$spacing-2;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .usedChip {
        background-color: main.$color-background-bluegray;
        color: main.$color-text-bluegray;
    }

    .newChip {
        background-color: main.$color-background-lightblue;
        color: main.$color-primary-500;
    }
</style>
